@font-face {
  font-family: Retina;
  font-weight: normal;
  src: url(./fonts/Retina/Retina-Regular.woff2) format("woff2"),
    url(./fonts/Retina/Retina-Regular.woff) format("woff"),
    url(./fonts/Retina/Retina-Regular.ttf) format("truetype"),
    url(./fonts/Retina/Retina-Regular.otf) format("opentype");
}
@font-face {
  font-family: Retina;
  font-weight: 500;
  src: url(./fonts/Retina/RetinaMedium.woff2) format("woff2"),
    url(./fonts/Retina/RetinaMedium.woff) format("woff"),
    url(./fonts/Retina/RetinaMedium.ttf) format("truetype"),
    url(./fonts/Retina/RetinaMedium.otf) format("opentype");
}
@font-face {
  font-family: Retina;
  font-weight: 800;
  src: url(./fonts/Retina/Retina-Bold.woff2) format("woff2"),
    url(./fonts/Retina/Retina-Bold.woff) format("woff"),
    url(./fonts/Retina/Retina-Bold.ttf) format("truetype"),
    url(./fonts/Retina/Retina-Bold.otf) format("opentype");
}
@font-face {
  font-family: Retina;
  font-weight: 900;
  src: url(./fonts/Retina/RetinaBlack.woff2) format("woff2"),
    url(./fonts/Retina/RetinaBlack.woff) format("woff"),
    url(./fonts/Retina/RetinaBlack.ttf) format("truetype"),
    url(./fonts/Retina/RetinaBlack.otf) format("opentype");
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.categories__gradient_white {
  background: linear-gradient(
    180deg,
    rgba(112, 130, 70, 0) 0%,
    rgba(112, 130, 70, 0.1) 100%
  );
}
.categories__gradient_red {
  background: linear-gradient(
    180deg,
    rgba(204, 38, 27, 0) 0%,
    rgba(204, 38, 27, 0.1) 100%
  );
}
.categories__gradient_yellow {
  background: linear-gradient(
    180deg,
    rgba(240, 158, 0, 0) 0%,
    rgba(240, 158, 0, 0.1) 100%
  );
}

.footer-menu {
  @apply dm__flex dm__flex-col dm__gap-3 dm__py-4;
}

.button {
  padding: 10px;
  margin: 10px;
}

.card {
  padding: 10px;
  margin: 10px;
}
